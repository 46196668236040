@charset "utf-8";

$red: #E53C21;
$yellow: #fecb00;
$greenblue: #7CC0A3;



$primary: #BCCB60;
$info: #999; // gray buttons
$warning: $yellow; // slider
// $danger: #e4ddc0; 
$success: $yellow; // buttons

$link: #35BF84;


$radius: 10px;
$box-radius: $radius;
$checkbox-radius: 6px;


$formwidth: 860px;

.bgColGrad1 {
  background-color: $greenblue;
}
.bgColGrad2 {
  background-color: #92BF67;
}
.bgColGrad3 {
  background-color: #BCCB60;
}

@import "../../node_modules/bulma/sass/utilities/_all.sass";
$navbar-breakpoint: $tablet;

@import "../../node_modules/bulma/bulma.sass";
@import "../../node_modules/bulma-checkradio/src/sass/index.sass";
@import "../../node_modules/bulma-slider/src/sass/index.sass";


.iconlist {
  .iconfact {
    text-align: center;
    color: #000000;
    border-radius: 33px;

    @extend .pt-6;
    @extend .pb-6;
    span {
      display:inline-block;
    }
    i {
       @extend .is-size-2;
      @extend .is-size-1-mobile;

    }
    div.quant span{
      // font-size: 36px;
      font-weight: bold;
      @extend .pb-2;
      @extend .is-size-2;
      @extend .is-size-2-mobile;
      }

    div.txt {
          @extend .pt-5;
          @extend .is-size-4-mobile;
          // font-weight: bold;
          text-transform: uppercase;
    }


  }
}

button i.fas {
  @extend .mr-2;

}
input[type=checkbox]:checked {
  @extend .has-no-border;
} 




/////////////////////////////////////////////*/
/**  Bulma Configuration
/////////////////////////////////////////////*/


.bulma-loader-mixin {

  @include loader;
  height: 5em;
  width:5em;
  margin: 0 auto;
  margin-top: 8vh;
}






.slider {
  @extend .pt-4;
  label {
    @extend h3;
    @extend .is-size-4;
    @extend .is-size-6-mobile;
    // text-align: center;
  }
}


/**  Bulma Forms  **/

input[type=range].slider {margin-top: 0;}
input[type="range"].slider::-webkit-slider-runnable-track {
  background-color: var(--track-color);
}
.percentColor { color: var(--track-color);}



input[type=text],
input[type=email],
input[type=tel] {@extend .input;}

input[type=checkbox]  {@extend .checkbox;}
button {
  @extend .is-block;
}
textarea {@extend .textarea;}
label {@extend .label;}

.select {
  width:100%; 
  select{
    width: 100%;
  }
}


.error {
  @extend .help;
  @extend .is-danger; 
}


button {
  @extend .button;
  @extend .is-success;
  // @extend .is-outlined;
  // @extend .is-rounded;
}


/**  Bulma Overwrites  **/
.navbar-item img {max-height: 100%;}

.navbar-brand, .navbar-tabs {
    align-items: center;
    justify-content: space-between;
}


.my-modal-wrapper {
    position: fixed;
    z-index: 10000;
    height: 100%;
    width: 100%;
    top: 0;
    left:0
}
.modal-card {
  // @extend .has-background-light;
  // @extend .box;
  padding: 0;
  @extend .p-4;
  .modal-card-title {flex-shrink: unset;}
}

.modal-card-head,
.modal-card-foot {
  @extend .has-background-white;
  border:none;
}
.modal-background {
  background-color:rgba(0,0,0,.75);
  // @extend .has-background-light;
  backdrop-filter: blur(10px);
}


.modal-content {
  border-radius: $radius;
}



.bulma-overlay-mixin {
  @include overlay(1.5rem);
  @extend .has-background-dark;

  border-radius: $radius;
  opacity: 0.5;
  padding: 0em;
  left:0;
  bottom:0;
  top:0;
  right:0;
   

  display: flex !important;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 4rem !important;
  }
}


/////////////////////////////////////////////*/
/**  Fonts          
/////////////////////////////////////////////*/

h1,h2,h3 {
  font-family: 'Cabin Sketch',Arial,sans-serif;
  @extend .is-size-1;
  @extend .is-uppercase;
  @extend .has-text-weight-bold;
}

h1 {@extend .is-size-1; @extend .is-size-3-mobile;}
h2 {@extend .is-size-2; @extend .is-size-3-mobile; line-height: 1.25}
h3 {@extend .is-size-3; @extend .is-size-4-mobile;}
h4 {@extend .is-size-4;}




/////////////////////////////////////////////*/
/**  Diverses          
/////////////////////////////////////////////*/
.message {box-shadow: $shadow; position:relative;}
.message-header {@extend .pl-5;@extend .pr-5;}
.hero {
  &.has-background {
    position: relative;
    overflow: hidden;
    }
  .hero-background {
    position: absolute;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    }
    h1 {
      
    }
}

@include widescreen {
  .hero h1{
    font-size: 4rem !important;
  }
}

/**  Fixed Footer  **/

#root{
  height: 100vh;
  display: flex;
  flex-direction: column;
}
footer{margin-top: auto;}
main {
    @extend .pb-6;
}


img, picture, video, svg {
  display: block;
  max-width: 100%;
  object-fit: contain;
}



// #root {
//   @extend .container;
// }


.App {
  @extend .container;
    @extend .is-fluid;
}

.my-page-container {
  @extend .container;
  @extend .p-4;


}

.form-width {
   max-width: $formwidth !important;
   
}


// Viewport Height Padding zb. Kleine Passende Stellen Message
@include tablet {.mt-vh {margin-top:8vh;}}

////////////////////////////////////////////
/**  JOB Filter
/////////////////////////////////////////////*/

    
.jobfiter {
  margin-top: -90px;
    @extend .container;
    // @extend .is-max-desktop;
    @extend .form-width;

    form {
        @extend .box;
        @extend .has-background-light;
        h2 {
          @extend .is-size-4-mobile;
        }
    }
    div.checkboxes {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        .tagbox {
          display:inline-block;
          width: 100%;
        }
        @include tablet {.tagbox {width: 50%;}}
        @include desktop {.tagbox {width: 33%;}}
    }

    .faecher {
      // @extend .has-background-white; 
      // @extend .p-4; 
    }
}

@include tablet {
  .jobfiter {
    margin-top: -90px;
  }
}
@include mobile {
  .jobfiter {
    margin-top: -30px;
  }
}


#job_results {

  ul {
    list-style-type: disc; 
    @extend .m-3;
    li {@extend .ml-3;}}
}

////////////////////////////////////////////
/**  Fun Classes          
/////////////////////////////////////////////*/
.btn {
  display:inline-block;
  border:1px solid $primary;
  color: $primary;
  text-decoration: none;
  padding:10px 30px;
}
.red {
    background-color: #CCC;
    color: red;
}
.blue {
    background-color: #CCC;
    color: blue;
}


////////////////////////////////////////////
/**  Testimonial       
/////////////////////////////////////////////*/

.testimonial {
  @extend .mt-5;
  img {
   max-width: 250px;
   margin-top: -100px;
  }
  .overlay {
    background-color:#CCC;
    @extend .box;
    @extend .pb-5;


  }



figure {


  // font-family: 'Raleway', Arial, sans-serif;
  position: relative;

  width: 100%;
  // color: #333;
  text-align: left;
  @extend .box;
  padding: 0;
display: flex;

    width: 100%;
}
figure * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
figure .imgWrap {
  flex: 1;

  // background-color:$primary;
  border-radius: 0 12px 12px 0;
  img {
    position: absolute;
    // left: 10;
    bottom:0;
    left:58%;
    margin: 0 auto;
    height:80%;
  vertical-align: bottom;

  }
}
figure figcaption {
  flex: 1;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // width: 60%;
  // position: absolute;
  background-color: #FFF;
  border-radius: 8px 0 0 8px;
}
figure blockquote, span.autor {
  position: relative;
  padding: 25px 50px 25px 50px;
  // font-size: 0.8em;
  font-weight: 500;
  text-align: left;
  margin: 0;
  line-height: 1.6em;
  font-style: italic;
}

 span.autor {
  display:block;
  @extend .mt-4;
 }
figure blockquote:before,
figure blockquote:after {
  font-family: 'FontAwesome';
  content: "\201C";
  position: absolute;
  font-size: 50px;
  opacity: 0.3;
  font-style: normal;
}
figure blockquote:before {
  top: 25px;
  left: 20px;
}
figure blockquote:after {
  content: "\201D";
  right: 20px;
  bottom: 0px;
}
figure .arrow {
  top: 30%;
  // left: 50%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #FFF;
  margin: 0;
  position: absolute;
}
figure .author {
  position: absolute;
  top: 100%;
  width: 60%;
  padding: 10px 15px;
  color: #ffffff;
  margin: 0;
  text-transform: uppercase;
}
figure .author h5 {
  opacity: 0.8;
  margin: 0;
  font-weight: 800;
}
figure .author h5 span {
  font-weight: 400;
  text-transform: none;
  padding-left: 5px;
}



@include mobile {
  figure .imgWrap {
    display:none;
    // flex: .5;
    img {
      //     left:70%;
      // height:80%;
    }
  }
}
@include tablet {

  figure .imgWrap {
            img {
      height:100%;
      left:60%;
    }
  }
}

@include desktop {

    figure {
    min-height: 470px;
    overflow:hidden;
  }
  figure figcaption {
    flex:.7;
  }
  figure .imgWrap {
    flex:.4;
    img {
      height:65%;
    }
  }
}

@include widescreen {
    figure {
    min-height: 360px;
  }
  figure figcaption {

    // flex:.4;
  }
  figure .imgWrap {
      img {
      height:75%;
      left:65%;
    }
  }
}
@include fullhd {
    figure {
    min-height: 320px;
  }
  figure .imgWrap {
      img {

      height:95%;
      left:65%;
    }
  }
}


}

